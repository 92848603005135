import { string, email, object } from 'yup';

export let schema = object().shape({
  visitor_name: string('Name should be strings').required(
    'Please write your name',
  ),
  visitor_email: string('Email should be in strings')
    .email('Email form is invalid')
    .required('Pease type your email'),
  visitor_message: string('Message should be in string form')
    .max(200, 'Message is too Long only 200 characters or less')
    .required('Please type a message'),
});
