import { useEffect, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useGetModelQuery, usePostFormMutation } from '../../API/config';
import { useFormik } from 'formik';
import { schema } from './controllers'; // yup validation schema

function Contact() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [congratDisplay, setCongratDispaly] = useState(false);

  // toolkit utils
  const { data } = useGetModelQuery('contacts');
  const [addForm, result] = usePostFormMutation();

  // form handler "formik"
  const formik = useFormik({
    initialValues: {
      visitor_name: '',
      visitor_email: '',
      visitor_message: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setIsSubmitted(true);
      addForm(values);
    },
  });

  useEffect(() => {
    setCongratDispaly(true);
    const timer = setTimeout(() => {
      setIsSubmitted(false);
      setCongratDispaly(false);
    }, 5000);

    formik.resetForm();

    return () => clearTimeout(timer);
  }, [result?.data?.success]);

  let congrats = () => {
    return (
      <Player
        src="https://assets10.lottiefiles.com/packages/lf20_lovdaj01.json"
        className="player"
        autoplay
        loop
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          margin: 'unset',
          top: '-178px',
          left: '0',
        }}
      />
    );
  };

  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">Contact Me</h2>
      <span className="section__subtitle">Get in touch</span>

      <div className="contact__container container grid">
        <div>
          {data?.data?.map(({ icon, title, link, contact }, index) => {
            return (
              <div key={index} className="contact__information">
                <i className={`${icon} contact__icon`}></i>

                <div>
                  <h3 className="contact__title">{title}</h3>
                  <span className="contact__subtitle">
                    <a className="contact__subtitle" href={link ?? '#'}>
                      {contact}
                    </a>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <form
          onSubmit={formik.handleSubmit}
          method="POST"
          id="contactForm"
          className="contact__form grid">
          {/* here is a congrates after submitting */}
          {congratDisplay ? congrats() : null}

          <div className="contact__inputs grid">
            <div className="contact__content">
              <label htmlFor="visitor_name" className="contact__label">
                Name
              </label>
              <input
                id="visitor_name"
                name="visitor_name"
                type="text"
                className="contact__input"
                onChange={formik.handleChange}
                value={formik.values.visitor_name}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.visitor_name && formik.errors.visitor_name ? (
                <small style={{ color: 'crimson' }}>
                  {formik.errors.visitor_name}
                </small>
              ) : null}
            </div>
            <div className="contact__content">
              <label htmlFor="visitor_email" className="contact__label">
                Email
              </label>
              <input
                id="visitor_email"
                name="visitor_email"
                type="email"
                className="contact__input"
                onChange={formik.handleChange}
                value={formik.values.visitor_email}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.visitor_email && formik.errors.visitor_email ? (
                <small style={{ color: 'crimson' }}>
                  {formik.errors.visitor_email}
                </small>
              ) : null}
            </div>
          </div>
          <div className="contact__content">
            <label htmlFor="visitor_message" className="contact__label">
              Message
            </label>
            <textarea
              id="visitor_message"
              name="visitor_message"
              cols="0"
              rows="7"
              className="contact__input"
              onChange={formik.handleChange}
              value={formik.values.visitor_message}
              onBlur={formik.handleBlur}
              required></textarea>
            {formik.touched.visitor_message && formik.errors.visitor_message ? (
              <small style={{ color: 'crimson' }}>
                {formik.errors.visitor_message}
              </small>
            ) : null}
          </div>

          <div>
            <button
              id="sendFeedback"
              type="submit"
              style={{
                border: 'none',
                cursor: isSubmitted ? 'wait' : 'pointer',
              }}
              disabled={isSubmitted}
              className="button button--flex">
              Send Message
              <i className="uil uil-message button__icon"></i>
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
