import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useGetModelQuery } from '../../API/config';

function About() {
  const { data, error, isLoading } = useGetModelQuery('about');
  return (
    <section className="about section" id="about">
      <h2 className="section__title">
        {isLoading ? (
          <Skeleton height={50} width={160} />
        ) : (
          data?.data?.section_title
        )}
      </h2>
      <span className="section__subtitle">
        {isLoading ? <Skeleton width={90} /> : data?.data?.section_subtitle}
      </span>

      <div className="about__container container grid">
        {isLoading ? (
          <Skeleton height={'100%'} style={{ borderRadius: '10px' }} />
        ) : (
          <img src={data?.data?.image} alt="" className="about__img" />
        )}

        <div className="about__data">
          <p className="about__description">
            {isLoading ? (
              <Skeleton count={3.5} />
            ) : (
              data?.data?.section_description
            )}
          </p>

          <div className="about__info">
            <div>
              <span className="about__info-title">
                {isLoading ? (
                  <Skeleton height={30} />
                ) : data?.data?.year_experiences > 9 ? (
                  data?.data?.year_experiences
                ) : (
                  '0' + data?.data?.year_experiences
                )}
                +
              </span>
              <span className="about__info-name">
                Years <br /> experience
              </span>
            </div>

            <div>
              <span className="about__info-title">
                {isLoading ? (
                  <Skeleton height={30} />
                ) : data?.data?.completed_projects > 9 ? (
                  data?.data?.completed_projects
                ) : (
                  '0' + data?.data?.completed_projects
                )}
                +
              </span>
              <span className="about__info-name">
                Completed <br /> project
              </span>
            </div>

            <div>
              <span className="about__info-title">
                {isLoading ? (
                  <Skeleton height={30} />
                ) : data?.data?.companies_worked > 9 ? (
                  data?.data?.companies_worked
                ) : (
                  '0' + data?.data?.companies_worked
                )}
                +
              </span>
              <span className="about__info-name">
                Companies <br /> worked
              </span>
            </div>
          </div>

          <div className="about__button">
            <a
              download=""
              href="assets/pdf/Resume-mohamed-ahmed.pdf"
              className="button button--flex">
              Download CV<i className="uil uil-download-alt button__icon"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
