import Skeleton from 'react-loading-skeleton';
import { useGetModelQuery } from '../../API/config';

function ProjectInMind() {
  const { data, error, isLoading } = useGetModelQuery('offer');
  return (
    <section className="project section">
      <div className="project__bg">
        <div className="project__container container grid">
          <div className="project__data">
            <h2 className="project__title">
              {isLoading ? (
                <Skeleton height={28} width={300} />
              ) : (
                data?.data?.offer_title
              )}
            </h2>
            <p className="project__description">
              {isLoading ? <Skeleton count={2.2} /> : data?.data?.offer_title}
            </p>
            <a href="#contact" className="button button--flex button--white">
              Contact Me
              <i className="uil uil-message project__icon button__icon"></i>
            </a>
          </div>

          <img
            src="assets/img/kat_project1.png"
            alt=""
            className="project__img"
          />
        </div>
      </div>
    </section>
  );
}

export default ProjectInMind;
