import './App.css';
import About from './components/about';
import Contact from './components/contact';
import Footer from './components/footer';
import Header from './components/header';
import Home from './components/home';
import ProjectInMind from './components/projectInMind';
import Projects from './components/projectsSlider';
import Qulifications from './components/qualifications';
import Services from './components/services';
import Skills from './components/skills';
import Testimonials from './components/testimonials';
import { Loader } from './components/loader';
import { useGetModelQuery } from './API/config';

function App() {
  const { data, error, isLoading } = useGetModelQuery('portfolio');

  if (isLoading) return <Loader />;

  return (
    <div>
      {/* <!--==================== HEADER ====================--> */}
      <Header />

      {/* <!--==================== MAIN ====================--> */}
      <main className="main">
        {/* <!--==================== HOME ====================--> */}
        <Home />

        {/* <!--==================== ABOUT ====================--> */}
        <About />

        {/* <!--==================== SKILLS ====================--> */}
        <Skills />

        {/* <!--==================== QUALIFICATION ====================--> */}
        <Qulifications />

        {/* <!--==================== SERVICES ====================--> */}
        <Services />

        {/* <!--==================== PORTFOLIO ====================--> */}
        <Projects />

        {/* <!--==================== PROJECT IN MIND ====================--> */}
        <ProjectInMind />

        {/* <!--==================== TESTIMONIAL ====================--> */}
        {/* <Testimonials /> */}

        {/* <!--==================== CONTACT ME ====================--> */}
        <Contact />
      </main>

      {/* <!--==================== FOOTER ====================--> */}
      <Footer />

      {/* <!--==================== SCROLL TOP ====================--> */}
      <a href="#" className="scrollup" id="scroll-up">
        <i className="uil uil-arrow-up scrollup__icon"></i>
      </a>
    </div>
  );
}

export default App;
