import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useGetModelQuery } from '../../API/config';
import { SkillListComponent } from './skillList';

function Skills() {
  const { data, error, isLoading } = useGetModelQuery('skills');
  return (
    <section className="skills section" id="skills">
      <h2 className="section__title">
        {isLoading ? (
          <Skeleton height={50} width={120} />
        ) : (
          data?.data?.skillSection?.section_title
        )}
      </h2>
      <span className="section__subtitle">
        {isLoading ? (
          <Skeleton width={90} />
        ) : (
          data?.data?.skillSection?.section_subtitle
        )}
      </span>

      <div className="skills__container container">
        <div className="skill__grid">
          {data?.data?.skills?.map((value, index) => {
            return (
              <div key={index}>
                <SkillListComponent
                  index={index}
                  value={value}
                  isLoading={isLoading}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Skills;
