import Preloader from '../preloaders/Loading-3D-Boxes.gif';

export const Loader = () => {
  return (
    <div
      style={{
        zIndex: '1000',
        backgroundColor: '#F8F9FE',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img
        src={Preloader}
        style={{
          backgroundPosition: 'center',
        }}
      />
    </div>
  );
};
