import Skeleton from 'react-loading-skeleton';
import { useGetModelQuery } from '../../API/config';

function Footer() {
  const { data, error, isLoading } = useGetModelQuery('socialMedia');
  return (
    <footer className="footer">
      <div className="footer__bg">
        <div className="footer__container container grid">
          <div>
            <h1 className="footer__title">Mohamed</h1>
            <span className="footer__subtitle">Web developer</span>
          </div>

          <ul className="footer__links">
            <li>
              <a href="#services" className="footer__link">
                Services
              </a>
            </li>
            <li>
              <a href="#portfolio" className="footer__link">
                Portfolio
              </a>
            </li>
            <li>
              <a href="#contact" className="footer__link">
                Contact Me
              </a>
            </li>
          </ul>

          <div className="footer__socials">
            {data?.data?.map((value, index) => {
              return (
                <a
                  key={index}
                  href={value.link}
                  target="_blank"
                  alt={value.name}
                  className="footer__social">
                  <i className={value.icon}></i>
                </a>
              );
            })}
          </div>
        </div>

        <p className="footer__copy">&#169; Mohamed. All right reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
