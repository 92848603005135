import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://portfolio-api.kat.sd/api' }),
  // baseQuery: fetchBaseQuery({ baseUrl: 'http://127.0.0.1:8000/api' }),
  endpoints: (builder) => ({
    getModel: builder.query({
      query: (name) => `/public/get/${name}`,
    }),
    postForm: builder.mutation({
      query: (payload) => ({
        url: `/public/formSubmit`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useGetModelQuery, usePostFormMutation } = portfolioApi;
