import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useGetModelQuery } from '../../API/config';

export default function Services() {
  const { data, error, isLoading } = useGetModelQuery('services');

  useEffect(() => {
    const modalViews = document.querySelectorAll('.services__modal'),
      modalBtns = document.querySelectorAll('.services__button'),
      modalCloses = document.querySelectorAll('.services__modal-close');

    let modal = function (modalClick) {
      modalViews[modalClick].classList.add('active-modal');
    };

    modalBtns.forEach((modalBtn, i) => {
      modalBtn.addEventListener('click', () => {
        modal(i);
      });
    });

    modalCloses.forEach((modalClose) => {
      modalClose.addEventListener('click', () => {
        modalViews.forEach((modalView) => {
          modalView.classList.remove('active-modal');
        });
      });
    });
  });

  return (
    <section className="services section" id="services">
      <h2 className="section__title">
        {isLoading ? (
          <Skeleton height={38} width={130} />
        ) : (
          data?.data?.serviceSection?.section_title
        )}
      </h2>
      <span className="section__subtitle">
        {isLoading ? (
          <Skeleton width={90} />
        ) : (
          data?.data?.serviceSection?.section_subtitle
        )}
      </span>

      <div className="services__container container grid">
        {isLoading ? (
          <>
            <Skeleton style={{ height: '250px' }} />
            <Skeleton style={{ height: '250px' }} />
            <Skeleton style={{ height: '250px' }} />
          </>
        ) : null}

        {/* <!-- ==================== SERVICES =================== --> */}
        {data?.data?.services?.map((value, index) => {
          return (
            <div key={index} className="services__content">
              <div>
                <i className={`${value.service_icon} services__icon`}></i>
                <h3 className="serivces__title">{value.service_name}</h3>
              </div>

              <span className="button button--flex button--small button--link services__button">
                View More
                <i className="uil uil-arrow-right button__icon"></i>
              </span>

              <div className="services__modal">
                <div className="services__modal-content">
                  <h4 className="services__modal-title">
                    {value.service_name}
                  </h4>
                  <i className="uil uil-times services__modal-close"></i>

                  <ul className="services__modal-services grid">
                    {value.services_detail_modal.map((item, ind) => {
                      return (
                        <li key={ind} className="services__modal-service">
                          <i className="uil uil-check-circle services__modal-icon"></i>
                          <p>{item.service_description}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
