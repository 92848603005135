import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useGetModelQuery } from '../../API/config';

function Qulifications() {
  const { data, error, isLoading } = useGetModelQuery('qualifications');
  return (
    <section className="qualification section">
      <h2 className="section__title">
        {isLoading ? (
          <Skeleton height={38} width={220} />
        ) : (
          data?.data?.qualificationSection?.section_title
        )}
      </h2>
      <span className="section__subtitle">
        {isLoading ? (
          <Skeleton width={90} />
        ) : (
          data?.data?.qualificationSection?.section_subtitle
        )}
      </span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className="qualification__button button--flex qualification__active"
            data-target="#education">
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>

          <div
            className="qualification__button button--flex"
            data-target="#work">
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Work
          </div>
        </div>
        <div className="qualification__sections">
          {/* <!-- ================ QUALIFICATION CONTENT 1 ================== --> */}
          <div
            className="qualification__content qualification__active"
            data-content
            id="education">
            {/* <!-- ================ QUALIFICATION 1 ================== --> */}

            {data?.data?.education?.map((value, index) => {
              return (
                <div key={index} className="qualification__data">
                  <div>
                    <h3 className="qualification__title">
                      {value.specialization}
                    </h3>
                    <span className="qualification__subtitle">
                      {value.country} - {value.college_name}
                    </span>
                    <div className="qualification__calender">
                      <i className="uil uil-calendar-alt"></i>
                      {value.started_at} - {value.ended_at}
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>
              );
            })}

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                {/* <!-- <span className="qualification__line"></span> --> */}
              </div>
            </div>
          </div>

          {/* <!-- ================ QUALIFICATION CONTENT 2 ================== --> */}
          <div className="qualification__content" data-content id="work">
            {/* <!-- ================ QUALIFICATION 2 ================== --> */}

            {data?.data?.job?.map((value, index) => {
              return (
                <div key={index} className="qualification__data">
                  <div></div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>

                  <div>
                    <h3 className="qualification__title">{value.position}</h3>
                    <span className="qualification__subtitle">
                      {value.country} - {value.company_name}
                    </span>
                    <div className="qualification__calender">
                      <i className="uil uil-calendar-alt"></i>
                      {value.started_at} - {value.ended_at}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                {/* <!-- <span className="qualification__line"></span> --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Qulifications;
