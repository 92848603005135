import Skeleton from 'react-loading-skeleton';

export const SkillListComponent = ({ value, isLoading, index }) => {
  return (
    <div
      key={index}
      className={`skills__content ${
        index === 0 ? 'skills__open' : 'skills__close'
      }`}>
      <div className="skills__header">
        <i className={`${value.skill_icon} skills__icon`}></i>

        <div>
          <h1 className="skills__title">
            {isLoading ? <Skeleton height={25} /> : value.skill_title}
          </h1>
          <span className="skills__subtitle">
            {isLoading ? <Skeleton /> : value.skill_description}
          </span>
        </div>

        <i
          className="uil uil-angle-down skills__arrow"
          onClick={(e) => {
            let element = e.target.parentNode.parentNode.classList;
            if (element.contains('skills__close')) {
              element.remove('skills__close');
              element.add('skills__open');
            } else {
              element.remove('skills__open');
              element.add('skills__close');
            }
          }}></i>
      </div>

      <div className="skills__list grid">
        {value.skill_lists?.map((item, ind) => {
          return (
            <div key={ind} className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">
                  {' '}
                  <div className="programming__langs_icons">
                    <img src={item.icon} width="30" height="30" />
                  </div>
                  {item.skill_name}
                </h3>
                <span className="skills__number">{item.skill_percentage}%</span>
              </div>
              <div className="skills__bar">
                <span
                  className="skills__percentage skill_progress"
                  style={{ width: item.skill_percentage + '%' }}></span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
