import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useGetModelQuery } from '../../API/config';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';

export default function Projects() {
  const { data, error, isLoading } = useGetModelQuery('portfolio');

  return (
    <section className="portfolio section" id="portfolio">
      <h2 className="section__title">
        {isLoading ? (
          <Skeleton height={38} width={130} />
        ) : (
          data?.data?.portfolioSection?.section_title
        )}
      </h2>
      <span className="section__subtitle">
        {isLoading ? (
          <Skeleton width={90} />
        ) : (
          data?.data?.portfolioSection?.section_subtitle
        )}
      </span>

      <div className="portfolio__container container swiper-container">
        {/* <!--==================== PORTFOLIO CONTENT ====================--> */}

        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          loop={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="swiper-wrapper">
          {data?.data?.projects?.map((value, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="portfolio__content grid swiper-slide">
                  <img
                    src={value.project_image}
                    alt=""
                    className="portfolio__img"
                  />

                  <div className="portfolio__data">
                    <h3 className="portfolio__title">{value.project_title}</h3>
                    <p className="portfolio__description">
                      {value.project_description}
                    </p>
                    <a
                      href={value.project_link}
                      target="_blank"
                      className="button button--flex button--small portfolio__button">
                      Demo
                      <i className="uil uil-arrow-right button__icon"></i>
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* 
        <div className="swiper-wrapper">
          {data?.data?.projects?.map((value, index) => {
            return (
              <div key={index} className="portfolio__content grid swiper-slide">
                <img
                  src={value.project_image}
                  alt=""
                  className="portfolio__img"
                />

                <div className="portfolio__data">
                  <h3 className="portfolio__title">{value.project_title}</h3>
                  <p className="portfolio__description">
                    {value.project_description}
                  </p>
                  <a
                    href={value.project_link}
                    target="_blank"
                    className="button button--flex button--small portfolio__button">
                    Demo
                    <i className="uil uil-arrow-right button__icon"></i>
                  </a>
                </div>
              </div>
            );
          })}
        </div> */}

        {/* <!-- Add Arrows --> */}
        {/* <div className="swiper-button-next">
          <i className="uil uil-angle-right-b swiper-portfolio-icon"></i>
        </div>
        <div className="swiper-button-prev">
          <i className="uil uil-angle-left-b swiper-portfolio-icon"></i>
        </div> */}
        {/* '\eb9f' */}
        {/* '\ec49' */}
        {/* <!-- Add pagination --> */}
        {/* <div className="swiper-pagination"></div> */}
      </div>
    </section>
  );
}
